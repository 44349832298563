import { marked } from 'marked'

marked.use({
    breaks: true,
    gfm: true,
})

export default function parseMarkdown(str, inline = true) {
    let res = str
    if (inline) {
        res = marked.parseInline(str)
    }
    else {
        res = marked.parse(str)
    }
    return res
}
